import React from "react";
import styles from './TermsAndConditions.module.css'

const TermsAndConditions = () => {

    const url = new URL(window.location.href);

    const svc = url.searchParams.get("svc");
  
  return (
    <>
    {
        svc == 'quizbox' ? 
        <>
        <h1 className={styles.header_name}>Quiz Box Game</h1>
        <div className={styles.container}>
        <h1 className={styles.header}>Terms and Conditions</h1>
        
        <section className={styles.section}>
          <h2>1.What is Quiz Box?</h2>
          <p>
            Quiz Box is an engaging and interactive game designed to enhance your knowledge and challenge your mind.
            It features a variety of quiz categories that cater to diverse interests, ensuring there's something for everyone!
          </p>
        </section>
      
        <section className={styles.section}>
          <h2>2.How to Play?</h2>
          <ul>
            <li>Select a quiz category of your choice.</li> 
            <li>Answer questions within the time limit to earn points.</li> 
            <li>Compete with friends or enjoy it solo to boost your knowledge and rank.</li> 
            <li>The more you play, the more points you earn!</li>
          </ul>
        </section>
      
        <section className={styles.section}>
          <h2>3.Categories</h2>
          <ul>
            Quiz Box offers a variety of exciting categories, including: <br />
            <li>Science & Technology </li>
            <li>History & Geography </li>
            <li>Sports & Entertainment</li> 
            <li>General Knowledge and many more!</li>
          </ul>
        </section>
      
        <section className={styles.section}>
          <h2>4.Why Play Quiz Box?</h2>
          <ul>
            Quiz Box isn't just a game – it's a journey of discovery and learning! <br />
             <li>Improve your knowledge on various topics.</li> <br />
             <li>Sharpen your problem-solving and critical-thinking skills.</li> <br />
             <li>Challenge yourself and climb the leaderboard for ultimate bragging rights!</li>
          </ul>
        </section>
      
        <footer className={styles.footer}>
          <p>
            Ready to take on the challenge? Start your Quiz Box journey today! <br />
            {/* For any questions, contact us at{" "} */}
            {/* <a href="mailto:support@quizbox.com">support@quizbox.com</a>. */}
          </p>
        </footer>
      </div>
      </>
      :
      svc == 'zeropoint'?
      <>
      <h1 className={styles.header_name}>Zero Point Game</h1>
      <div className={styles.container}>
  <h1 className={styles.header}>Terms and Conditions</h1>
  
  
  <section className={styles.section}>
        <h2>1. Introduction</h2>
        <p>
        These terms and conditions govern the use of the "ZeroPoint"
                service (hereinafter referred to as "the Service") provided by
                Visiontrek Communication. By accessing or using the Service, you
                agree to comply with and be bound by these terms. If you do not
                agree with any part of these terms, you may not use the Service.
        </p>
      </section>


      <section className={styles.section}>
        <h2>2. Service Overview</h2>
        <p>
        Users will be presented with a set of options, where three
                options are correct, and one is intentionally incorrect. Users
                are required to choose the incorrect option.
        </p>
      </section>

  <section className={styles.section}>
    <h2>3. How to Play?</h2>
    <ul>
      <li>You will be presented with a set of options: three are correct, and one is intentionally incorrect.</li> 
      <li>Your task is to identify and click on the incorrect option.</li> 
      <li>When you select the incorrect option, a tick mark will indicate that you’re right, and your points will be deducted.</li> 
      <li>The game concludes either:</li>
      &nbsp;&nbsp;- When you fail to complete the quiz within 60 seconds. <br />
      &nbsp;&nbsp;- Or when you provide three correct answers, resulting in your defeat.
    </ul>
  </section>

  <section className={styles.section}>
    <h2>4. How to Win?</h2>
    <p>
      To win Zero Point Game, you must strategically pick the incorrect options until your points reach zero.
      Be quick, careful, and calculated to outsmart the challenge!
    </p>
  </section>

  <section className={styles.section}>
    <h2>5. Why Play Zero Point Game?</h2>
    <ul>
       <li>Test your decision-making skills under pressure.</li> 
       <li>Engage in a unique twist to traditional quiz games.</li> 
       <li>Have fun while challenging yourself to think unconventionally.</li> 
     <li>Compete with friends to see who can hit zero points first!</li>
    </ul>
  </section>

  <footer className={styles.footer}>
    <p>
      Ready to take on the Zero Point challenge? Start playing now and aim for the ultimate goal: *Zero*! <br />
      {/* For any questions, contact us at{" "} */}
      {/* <a href="mailto:support@zeropointgame.com">support@zeropointgame.com</a>. */}
    </p>
  </footer>
</div>
      </>
      :
<>
<h1 className={styles.header_name}>Digitize AI</h1>
      <div className={styles.container}>
      <h1 className={styles.header}>Terms and Conditions</h1>
      {/* <p className={styles.lastUpdated}>Last Updated: [Insert Date]</p> */}

      <section className={styles.section}>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using Digitize AI, you agree to these Terms and our
          Privacy Policy. If you are using Digitize AI on behalf of an
          organization, you confirm that you are authorized to agree to these
          Terms on their behalf.
        </p>
      </section>

      <section className={styles.section}>
        <h2>2. Services Provided</h2>
        <p>
          Digitize AI offers tools to enhance creativity and productivity,
          including conversational AI capabilities. These tools are designed to
          support users in generating ideas, learning, and personal growth.
        </p>
      </section>

      <section className={styles.section}>
        <h2>3. User Responsibilities</h2>
        <ul>
           <li>You must be at least 18 years old to use our services.</li>
           <li>You agree to use Digitize AI responsibly and not engage in
          activities that harm the platform, its users, or third parties.</li>
           <li>You will not use Digitize AI to create, share, or promote illegal,
          harmful, or offensive content.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>4. Intellectual Property</h2>
        <p>
          All content, features, and tools provided by Digitize AI, including
          trademarks, logos, and software, are the property of Digitize AI or
          its licensors. Users may not copy, distribute, or reverse-engineer
          any part of our services without prior written consent.
        </p>
      </section>

      <section className={styles.section}>
        <h2>5. Privacy</h2>
        <p>
          Your privacy is important to us. Our use of your personal data is
          governed by our Privacy Policy. By using Digitize AI, you consent to
          the collection and use of your data as outlined in the Privacy
          Policy.
        </p>
      </section>

      <section className={styles.section}>
        <h2>6. Disclaimer of Warranties</h2>
        <p>
          Digitize AI provides services on an "as-is" and "as-available" basis.
          We do not guarantee uninterrupted, error-free services or outcomes.
          Users assume full responsibility for the use of our tools and any
          consequences thereof.
        </p>
      </section>

      {/* <section className={styles.section}>
        <h2>7. Governing Law</h2>
        <p>
          These Terms are governed by the laws of [Your Country/Region], without
          regard to conflict of law principles. Any disputes shall be resolved
          exclusively in the courts of [Your Country/Region].
        </p>
      </section> */}

      {/* <footer className={styles.footer}>
        <p>
          For any questions, contact us at{" "}
          <a href="mailto:support@digitizeai.com">support@digitizeai.com</a>.
        </p>
      </footer> */}
    </div>
   </>

    }
</>

  );
};



export default TermsAndConditions;
