import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SubscribePageNew = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); 

  const logoMap = {
    QuizBox: "QuizBox",
    ZeroPoint: "ZeroPoint",
    SMSAI: "SMSAI",
  };

  const successUrlMap = {
    Quizbox_1: "https://apiquizdemo.gamewins.co.za/",
    ZeroPoint: "https://zeropoints.gameit.in/home",
    SMSAI: "https://www.askme.digitizeconsultant.com",
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let svc = params.get("svc");
    let extRef = params.get("ext_ref");
    let serviceName = "Quizbox_1";
    let offerCode = "";



    if (svc) {
      svc = svc.toLowerCase();
      if (svc === "quizbox") {
        offerCode = "001039236978";
        serviceName = "Quizbox_1";
      } else if (svc === "zeropoint") {
        offerCode = "001039236978";
        serviceName = "ZeroPoint";
      } else {
        offerCode = "001039236751";
        serviceName = "SMSAI";
      }
    }

    // Trigger subscription API call
    subscribeUser(offerCode, serviceName, extRef);
  }, [location.search]);

  const subscribeUser = async (offerCode, serviceName, extRef) => {
    if (!offerCode || !serviceName) {
      toast.error("Invalid service details. Please try again.");
      setLoading(false);
      return;
    }

    const requestBody = {
      offerCode,
      serviceName,
      successUrl: successUrlMap[serviceName],
      failureUrl: "https://landingpage.digitizeconsultant.com/error",
      errorUrl: "https://landingpage.digitizeconsultant.com/error",
    };
    console.log(requestBody);
    
    try {
      const response = await fetch("https://evinadb.africomltd.com/app/v1/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      console.log("response : + " ,responseData)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if(responseData.reference){
        //-----send promotion data to backend 
        sendPromotionData(extRef, responseData.reference, serviceName);
      }

      if (responseData.url) {
        window.location.href = responseData.url; 
      } else {
        toast.error("No URL found in the response.");
        navigate("/error");
      }
    } catch (error) {
      console.error("Error during subscription:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };



//-----------------------------Promotion data-------
  const sendPromotionData = async (extRef, reference, serviceName) => {
    const promotionRequestBody = {
      ext_ref: extRef,
      serviceName: serviceName,
      reference: reference, 
    };
    try{
      //notificationafricom.digitizeconsultant.com
      const response = await fetch("https://notificationafricom.digitizeconsultant.com/promotion/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(promotionRequestBody),
      });
    }catch(error){
      toast.error("error on sending promotion data");
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default SubscribePageNew;
