import React from "react";
import {Routes,Route} from 'react-router-dom'
import SubscribePageNew from "../src/pages/SubscribePageNew.js";
import TermsAndConditions from "./pages/TermsAndCondition.js";
import ErrorPage from "./pages/ErrorPage.js";
const App = () => {
return (
  // <div className="App">
   <Routes>
    <Route path='/' element={ <SubscribePageNew/>}/>
    <Route path='/terms' element={ <TermsAndConditions/>}/>
    <Route path='*' element={ <ErrorPage/>}/>
   
   </Routes>
  // </div>
);
}


export default App;
